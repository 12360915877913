/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023 纷析云（杭州）技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年10月17日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import AppContent from "@components/AppContent.vue";
import AccountDateChoose from "@components/AccountDateChoose.vue";
import SubMenu from "@components/common/sub-menu.vue";
import Chart from "@/views/app/chart/echarts.vue";
import RouterLink from "@components/RouterLink.vue";

export default {
  install(app) {
    app.component("app-content", AppContent);
    app.component("account-date-choose", AccountDateChoose);
    app.component('sub-menu', SubMenu);
    app.component('Chart', Chart);
    app.component('RouterLink', RouterLink);
  }
}
