<template>
  <div class="flex items-center">
    <div class="flex h-full bg-white-color" v-if="showMove" @click="moveScroll(-200)">
      <div class="bg-white-color h-full w-36px flex items-center justify-center text-hover">
        <i class="h-icon-left"></i>
      </div>
      <div class="h-full w-1px bg-gray2-color"></div>
    </div>
    <div ref="tabC" class="flex-1 overflow-x-auto overflow-y-hidden scrollbar-hide">
      <Tabs ref="tab" class-name="h-tabs-card h-tabs-card-plain flex" :datas="tabs" v-model="selectedTab" @clickItem="tabClick">
        <template #item="{tab}">
          <span class="inline-flex items-center"><i v-if="tab.key==='Home'" class="fi fi-home !w-16px !h-16px mr-4px"></i> {{ tab.title }}</span>
          <i @click.stop="closeTab(tab.key)" class="h-icon-close-min ml-8px" v-if="tab.key!=='Home'"></i>
        </template>
      </Tabs>
    </div>
    <div class="flex items-center h-full bg-white-color">
      <div class="h-full w-1px bg-gray2-color"></div>
      <div class="bg-white-color h-31px w-39px flex items-center justify-center text-hover" v-if="showMove" @click="moveScroll(200)">
        <i class="h-icon-right"></i>
      </div>
      <div v-if="showMove" class="h-full w-1px bg-gray2-color"></div>
      <DropdownMenu
          :datas="param"
          placement="left-start"
          :toggle-icon="false"
          class-name="h-text-dropdown"
          @clickItem="trigger"
      >
        <div class="bg-white-color h-full w-36px flex items-center justify-center text-hover">
          <i class="h-icon-menu"></i>
        </div>
      </DropdownMenu>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import {scrollIntoView} from 'heyui.ext';

export default {
  name: "AppTabs",
  data() {
    return {
      selectedTab: "Home",
      showMove: false,
      timer: null,
      param: [
        {title: '关闭当前', key: 'c'},
        {title: '关闭其他', key: 'o'},
        {title: '关闭所有', key: 'a'}
      ]
    };
  },
  watch: {
    currentTab(val) {
      this.selectedTab = val;
      location.hash = val;
    },
    tabs: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          this.showMove = this.$refs.tab.$el.scrollWidth > this.$refs.tabC.offsetWidth;
          scrollIntoView(document.querySelector('.h-tabs-card-plain .h-tabs-item-selected'))
        })
      }
    }
  },
  methods: {
    ...mapMutations(['changeTab', 'newTab', 'updateTabs']),
    closeTab(key) {
      this.$store.commit('closeTab', key);
    },
    tabClick({key}) {
      if (this.currentTab !== key) {
        this.$store.commit('changeTab', key);
      }
    },
    trigger(key) {
      switch (key) {
        case 'a':
          this.updateTabs([{
            key: "Home",
            title: "首页",
            icon: 'fi fi-home'
          }])
          this.changeTab('Home')
          break
        case 'o':
          this.updateTabs(this.tabs.filter(val => ['Home', this.currentTab].includes(val.key)))
          break
        case 'c':
          if (this.currentTab !== 'Home') {
            this.$store.commit('closeTab', this.currentTab);
          }
          break
      }
    },
    scroll(pos, step, end) {
      if (this.$refs.tabC) {
        if (pos > 0) {
          this.$refs.tabC.scrollLeft += step;
          let scrollWidth = this.$refs.tabC.scrollWidth - this.$refs.tabC.offsetWidth;
          if (scrollWidth === this.$refs.tabC.scrollLeft || this.$refs.tabC.scrollLeft >= end) {
            console.log("r" + this.timer)
            clearInterval(this.timer)
            this.timer = null;
          }
        } else {
          this.$refs.tabC.scrollLeft -= step;
          if (this.$refs.tabC.scrollLeft === 0 || this.$refs.tabC.scrollLeft <= end) {
            console.log("l" + this.timer)
            clearInterval(this.timer)
            this.timer = null;
          }
        }
      }
    },
    moveScroll(pos) {
      let end = Math.max(0, this.$refs.tabC.scrollLeft + pos);
      const step = Math.floor(Math.abs(pos) / (300 / 16.7));
      if (!this.timer) {
        this.timer = setInterval(() => this.scroll(pos, step, end), 16.7);
      }
    }
  },
  computed: {
    ...mapState(['tabs', 'currentTab']),
    ...mapGetters(['tabMap'])
  },
  created: function () {
    const currentTab = localStorage.getItem("currentTab")
    if (currentTab) {
      let tab = JSON.parse(currentTab);
      this.selectedTab = tab.key;
      this.newTab(tab);
    }
  }
};
</script>
<style scoped lang="less">
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

:deep(.h-tabs-card-plain .h-tabs-item ) {
  border-right: 1px solid @gray2-color;
}

.h-icon-close-min {
  &:hover {
    color: @yellow-color;
    font-weight: bold;
  }
}
</style>
