<template>
  <div class="echarts-vue"></div>
</template>

<script>
import debounce from 'lodash.debounce';

import * as echarts from 'echarts';
import 'echarts/extension/dataTool'
import theme from './theme';

export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    initOption: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.chart.setOption(this.options);
      }
    }
  },
  methods: {
    update(options) {
      this.chart.setOption(options);
    },
    init() {
      let chart = this.chart = echarts.init(this.$el, theme, this.initOption);
      this.chart.setOption(this.options);

      this.resizeHanlder = debounce(() => {
        chart.resize();
      }, 100, {leading: true});
      window.addEventListener('resize', this.resizeHanlder);
      this.listener = G.addlistener('page_resize', () => {
        chart.resize();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHanlder);
    G.removelistener(this.listener);
    this.chart.dispose();
    this.chart = null;
  }
};
</script>
<style lang="less">
.echarts-vue {
  height: 400px;
  overflow: hidden;
}
</style>
