<template>
  <div class="login-frame">
    <div class="login-frame-title">忘记密码</div>
    <Form class="login" ref="loginForm" :model="form" :rules="{}" :validOnChange="true">
      <FormItem :showLabel="false" required prop="mobile" label="账号" class="!pb-25px">
        <div class=" h-input-prefix">
          <div class=" h-input-prefix-icon">
            <i class="fa fa-user"></i>
          </div>
          <Input v-model="form.mobile" type="text" placeholder="请输入手机号" class="h-42px"/>
        </div>
      </FormItem>
      <FormItem :showLabel="false" required prop="code" label="验证码" class="!pb-25px">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon h-input-prefix-icon-code"></div>
          <div class="h-input-group" style="width: auto;flex: 1">
            <Input v-model="form.code" type="text" placeholder="验证码" maxlength="4"/>
            <SmsVerificationCode :mobile="form.mobile"/>
          </div>
        </div>
      </FormItem>
      <FormItem :showLabel="false" required prop="newPassword" label="新密码" class="!pb-25px">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon">
            <i class="fa fa-lock"></i>
          </div>
          <Input v-model="form.newPassword" placeholder="请输入新密码" type="password"/>
        </div>
      </FormItem>
      <FormItem :showLabel="false" required prop="repeatPassword" label="确认新密码" class="!pb-25px">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon">
            <i class="fa fa-lock"></i>
          </div>
          <Input v-model="form.repeatPassword" placeholder="请再次输入新密码" type="password"/>
        </div>
      </FormItem>
      <FormItem :showLabel="false" class="!pb-0px">
        <Button @click="regSubmit" :loading="loading" color="primary" block>修 改 密 码</Button>
        <div class=" my-32px text-center">
          <span class="text-hover" @click="$emit('changeView','LoginForm')">返回登录</span>
        </div>
      </FormItem>
    </Form>
  </div>
</template>
<script>
import SmsVerificationCode from "../../components/SmsVerificationCode"
import common from "@api/common";
import {message} from "heyui.ext";

export default {
  name: 'ForgotPassword',
  components: {SmsVerificationCode},
  data() {
    return {
      form: {
        mobile: "",
        code: "",
        newPassword: "",
        repeatPassword: "",
      },
      loading: false
    };
  },
  methods: {
    regSubmit() {
      let validResult = this.$refs.loginForm.valid();
      if (validResult.result) {
        this.loading = true;
        common.resetPassword(this.form).then(() => {
          message("密码重置成功！");
          this.$emit('changeView', 'LoginForm');
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  }
}
</script>
